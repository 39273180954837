.success_inset{
    width: 375px;
    height: 429px;
    flex-shrink: 0;
    border-radius: 50px;
background: #121212;
margin-top: -470px;
position: absolute;
}
.amount_container{
    width: 375px;
height: 812px;
border-radius: 50px;
background: #FFF;
opacity: .2;
}
.amount_container > svg{
    width: 24px;
height: 24px;
flex-shrink: 0;
margin: 54px 724px 319px 32px;
}
.amount_container > p{
    color: #121212;
text-align: center;
font-family: "TT Firs Neue Trial Var Roman";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 117%; /* 18.72px */
margin: -343px 722px 92px 91px;
}
.amount_container > h2{
    color: #121212;
    font-family: "TT Firs Neue Trial Var Roman";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 117%; /* 42.12px */
margin: -282px 618px 96px 97px;
}
.amount_container > h5{
    color: #121212;
font-family: "TT Firs Neue Trl";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: -222px 596px 139px 149px;
}
.image_frame{
    width: 311px;
height: 116px;
flex-shrink: 0;
border-radius: 16px;
background: #EEEFF6;
margin: -100px 447px 32px 32px;
}
.image_frame > svg{
    width: 50px;
height: 50px;
flex-shrink: 0;
margin: 33px 33px 130px 131px;
}
.success_inset > img{
    width: 100.486px;
    height: 80.861px;
    flex-shrink: 0;
    margin: 50px 290px 78px 120px;
}
.success_inset > p{
    color: #FFF;
text-align: center;
font-family: "TT Firs Neue Trial Var Roman";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 117%; /* 18.72px */
margin: -63px 247px 58px 57px;
}
.success_btn .btn{
    width: 311px;
height: 70px;
flex-shrink: 0;
border-radius: 50px;
background: #CDFF65;
margin: 20px 113px 32px 32px;
position: relative;
cursor: pointer;

color: #121212;
text-align: center;
font-family: "TT Firs Neue Trl";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;


}