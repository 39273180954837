@font-face {
    font-family: TT Firs Neue Trl;
    src: url(../Font/tt_firs_neue/TT\ Firs\ Neue\ Trial\ Light.ttf);
}


.acs > p{
    color: #FFF;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 37.44px */
    margin: 133px 605px 89px 32px;
    width: 254px;
    height: 74px;
}
.image img{
    width: 190.486px;
    height: 154.861px;
    flex-shrink: 0;
    margin: 329px 328.14px 106.51px 78px;
}
.frame .btn2 > input{
    width: 311px;
    height: 70px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #CDFF65;
    margin: 5px 10px 32px 32px;

    color: #121212;
    text-align: center;
    font-family: TT Firs Neue Trl;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}
.p_bar {
    display: flex;
    margin: 231px 576px 218px 32px;
    position: absolute;
    width: 120px;
    justify-content: space-between;
}
.p_bar > .pogress1, .pogress2{
    width: 29px;
    height: 5px;
    border-radius: 50px;
    background: #2C3519;
}
.p_bar > .pogress3{
    width: 39px;
    height: 5px;
    border-radius: 50px;
    background: #CDFF65;
}
