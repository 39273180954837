@font-face {
    font-family: TT Firs Neue Trial Var Roman;
    src: url(../Font/tt_firs_neue/TT\ Firs\ Neue\ Trial\ Var\ Roman.ttf);
}
.container{
    width: 375px;
    height: 96vh;
    border-radius: 50px;
    background: #FFF;
    margin: auto;
    /* position: absolute; */
}


.frame > img{
    width: 105.683px;
    height: 24px;
    flex-shrink: 0;
    margin: 60px 0 auto;
   
}
.container .frame > .inset_container{
    width: 375px;
    height: 605px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #121212;
    position: relative;
    margin-top: 340px;
}
.text h3{
    color: #FFF;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 37.44px */
    width: 252px;
    height: 74px;
    margin: 64px 540px auto;
    position: absolute;
}
.input_box{
    margin: 165px 250px auto;
    position: absolute;
}
.input_box form > div, input{
    width: 311px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 16px;
    border: none;
    background: #EEEFF6;

    color: #868686;
    font-family: TT Firs Neue Trl;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    
}
.input_box form > div{
    margin-top: 16px;
    border: 2px solid #CDFF65;
}
.lnk button{
    width: 311px;
    height: 70px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #CDFF65;
    cursor: pointer;
    margin: 85px 0 auto;

    color: #121212;
    text-align: center;
    font-family: TT Firs Neue Trl;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    animation: Instant;
}
div p{
    color: #EEEFF6;
    text-align: center;
    font-family: TT Firs Neue Trl;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    width: 100%;
    margin: 540px 82px auto;
    position: absolute;
    
}
p .lnk{
    text-decoration: none;
    color: white;
    margin-left: 5px;
}
.progress_bar{
    display: flex;
    margin: 160px 511px 290px 46px;
    position: absolute;
    width: 130px;
    justify-content: space-between;
}
.pb1{
    width: 39px;
    height: 5px;
    border-radius: 50px;
    background: #CDFF65;

}
.pb2, .pb3{
    width: 29px;
    height: 5px;
    border-radius: 50px;
    background: #2C3519;
}
@media screen and (max-width: 412px) {
    .container{
      border-radius: 0;
      width: 100%;
      height: 100%;
    }
    .container .inset_container{
        border-radius: 50px 50px 0 0;
    }
  }