.arrow_back{
    cursor: pointer;
}
.am_text > h2{
color: #121212;
text-align: center;
font-family: TT Firs Neue Trial Var Roman;
font-size: 16px;
font-style: normal;
font-weight: 500;
width: 100%;
line-height: 117%; /* 18.72px */
margin: -310px 727px 120px 0px;
}
.depo_inset{
    display: block;
    background-color: #EEEFF6;
    width: 311px;
    height: 140px;
    align-items: center;
    border-radius: 10px;
    margin: -370px 727px 32px 32px;
    justify-content: center;
    align-items: center;
    
    
}
.depo_inset .depo_tittle{
    display: flex;
    align-items: center;
    width: 100%;
}
.depo_tittle .card_logo > p{
    background-color: rgb(246, 241, 241);
    margin: -5px 724px 319px 3px;
    width: 18px;
    align-items: center;
    border-radius: 100px;
    border: 1px solid gray;
    text-align: center;
    padding: 5px;
}
.depo_header{
    display: block;
}
.depo_header h3{
    margin-left:50px;
    width: 100%;
    color: #121212;
    text-decoration: none;
    
}
.depo_header p{
    margin: -15px 724px 319px 50px;
    color: #121212;

}
.recomemded{
    display: flex;

}
.depo_inset .recomemded .green > p{
    margin: 10px 724px 319px 45px; 
    background-color: #17af17;
    width: 25%;
    text-align: center;
    padding: 4px;
    border-radius: 50px;
    font-weight: 500;
}
.depo_inset .recomemded .red > p{
    margin: 10px 724px 319px 155px; 
    background-color: rgb(255, 80, 80);
    width: 16%;
    text-align: right;
    padding: 3px;
    border-radius: 50px;
    font-weight: 500;
    display: flex;
    max-height: 20px;
}
.depo_inset .recomemded .visa > p{
    margin: 7px 724px 30px 230px; 
    color: #121212;
    font-size: 15px;
    width: 15%;
    text-align: right;
    padding: 4px;
    border-radius: 50px;
    font-weight: 800;
    color: rgb(4, 4, 99);
    
}
.depo_inset > p{
    margin: 38px 724px 319px 30px; 
    color: rgb(240, 113, 15);
    width: 70%;
    font-weight: 600;
}
.arrow_foward{
    margin-left: 130px;
}

.bank-details{
    width: 311px;
height: 215px;
flex-shrink: 0;
border-radius: 16px;
background: #EEEFF6;
margin: -20px 363px 32px 32px;
}
.bank-details > p{
    color: #121212;
font-family: TT Firs Neue Trl;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: 146%; /* 17.52px */
margin: 10px 152px 35px 24px;
width: 252px;
height: 54px;
text-align: left;
}
.bank-details .details1, .details2, .details3{
     display: flex;
     width: 252px;
     margin-top: -10px;
     
}
.bank-details .details1 > label{
    color: #121212;
font-family: TT Firs Neue Trl;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 103px 102px 220px 24px;

}
.bank-details .details1 > p{
    color: #121212;
font-family: TT Firs Neue Trl;
font-size: 12px;
font-style: normal;
font-weight: 00;
line-height: normal;
margin: 103px 102px 24px 167px;
}
.bank-details .details2 > label{
    color: #121212;
font-family: TT Firs Neue Trl;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: -190px 64px 190px 24px;
}
.bank-details .details2 > p{
    color: #121212;
font-family: TT Firs Neue Trl;
font-size: 12px;
font-style: normal;
font-weight: 00;
line-height: normal;
margin: -190px 64px 54px 187px;
}
.bank-details .details3 > label{
    color: #121212;
font-family: TT Firs Neue Trl;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: -161px 24px 202px 24px;
}
.bank-details .details3 > p{
    color: #121212;
font-family: TT Firs Neue Trl;
font-size: 12px;
font-style: normal;
font-weight: 00;
line-height: normal;
margin: -161px 24px 27px 192px;
}
.bank-details > h3{
    margin: 0px 0px 10px 20px;
}
.po_inset{
    width: 375px;
height: 637px;
flex-shrink: 0;
border-radius: 50px;
background: #121212;
position: absolute;
margin-top: -95px;
}
.po_inset > h1{
    color: #FFF;
font-family: TT Firs Neue Trial Var Roman;
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: 117%; /* 42.12px */
margin: 10px 247px 96px 97px;

}
.po_inset > p{
    color: #EEEFF6;
    text-align: center;
    font-family: TT Firs Neue Trl;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: -100px 224px 144px 144px;
}
.po_inset .btn > button{
    margin: -44px 113px 32px 32px;
    position: absolute;
}
.depo_option_scroll{
    overflow-y: scroll;
    position: relative;
}
.depo_option_scroll::-webkit-scrollbar{
    display: none;
}