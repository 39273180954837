.arrow_back svg{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin: 64px 724px 319px 32px;
}
.am_text p{
    color: #121212;
text-align: center;
font-family: TT Firs Neue Trial Var Roman;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 117%; /* 18.72px */
margin: -340px 726px 142px 142px;
}
.arrow_down{
    width: 20px;
height: 20px;
flex-shrink: 0;
margin: -340px 726px 93px 262px;
/* position: absolute; */
display: flex;
}
.usdt p{
    color: #8F8F8F;
font-family: TT Firs Neue Trial Var Roman;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 117%; /* 14.04px */
margin: -109px 729px 64px 286px;
}
.flag {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin: -116px 724px 32px 319px; 
}

.am_inset{
    width: 375px;
height: 678px;
flex-shrink: 0;
border-radius: 50px;
background: #121212;
margin-top: 14px;
    position: relative;
}
.am_inset form{
    width: 100vw;
}
#usdt_label{
    margin: 48px 615px 257px 32px;
}
#usdt_label, #NGN_label{
    color: #FFF;
font-family: TT Firs Neue Trl;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;

width: 86px;
height: 15px;
position: absolute;
}
.inp1 > input{
    width: 311px;
height: 60px;
flex-shrink: 0;
border-radius: 16px;
background: #EEEFF6;
margin: 69px 549px 32px 32px;

color: #4F56D3;
font-family: TT Firs Neue Trl;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding:  14px 15px -210px 24px;
text-align: left;
}
#NGN_label{
    margin: 0px 518px 254px 32px;
    width: 89px;
    height: 15px;
}
.inp2 > input{
    width: 311px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #EEEFF6;
    margin: 25px 452px 32px 32px;
    position: absolute;

    
    color: #4F56D3;
    font-family: TT Firs Neue Trl;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding:  14px 15px -210px 24px;
    text-align: left;

}
.swap{
    width: 311px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 16px;
border: 1px solid #CDFF65;
background: #121212;
margin: 100px 358px 32px 32px;
justify-content: center;
align-items: center;
display: flex;
cursor: pointer;
}
.swap > p{
    width: 32px;
height: 15.625px;
flex-shrink: 0;
color: #FFF;
font-family: TT Firs Neue Trl;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
position: relative;
margin: 130.12px 22.25px 129px 10px;

}
.am_inset > p{
    margin: -20px 20px 10px 85px;
    width: 200px;
    height: 15px;
    position: absolute;
    color: rgb(105, 101, 101);
    font-weight: 600;
}

.am_inset form .button{
    margin: 120px 0px 32px 32px;
}
#usdt_label2{
    margin: 48px 615px 257px 32px;
    width: 89px;
    
    color: #FFF;
    font-family: TT Firs Neue Trl;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 15px;
    position: absolute;
}

