html{
background-color: black;
margin: 0;
padding: 0;
position: fixed;


}
 body {
  /* margin: 0; */
  /* overflow: scroll; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to bottom right, black, rgb(0, 43, 95), black);
  background-repeat: no-repeat;
  background-size: cover;
  margin:0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  
}
.frame{
  border-radius: 50px;
  background: #121212;
  width: 375px;
  height: 98vh;
  margin: auto;
  border: 2px solid gold;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  

}
.frame::-webkit-scrollbar{
  display: none;
}

@media screen and (max-width: 412px) {
  body{
    margin: 0px;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    position: static;
    overflow: hidden;
    border-radius: 30px 0px;
  }
  .frame{
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
