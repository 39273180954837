@font-face {
    font-family: TT Firs Neue Trl;
    src: url(../Font/tt_firs_neue/TT\ Firs\ Neue\ Trial\ Light.ttf);
}


.back_arrow{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin: 78px 0 319px 32px;
}
.text{
    margin: -360px 0 80px -12px;

}
.text h3{
    height: 74px;
    width: 263px;
    color: #FFF;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 37.44px */
}

.pb1_sp, .pb3_sp{
    width: 29px;
    height: 5px;
    border-radius: 50px;
    background: #2C3519;
}
.pb2_sp{
    width: 39px;
    height: 5px;
    border-radius: 50px;
    background: #CDFF65;
}
.sm_input{
    display: flex;
    justify-content: space-between;
    width: 300px;
    
}
.sm_input > input{
    width: 65px;
    height: 65px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #FFF;
    outline: none;
    scroll-behavior: none;
    font-size: 25px;
    font-weight: 600;
    font-family: TT Firs Neue Trl;
}

.sp_btn_con input{
    width: 311px;
    height: 70px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #CDFF65;
    margin: 200px 113px 32px 32px;

    color: #121212;
    text-align: center;
    font-family: TT Firs Neue Trl;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
