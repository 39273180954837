.send_head svg{
    width: 24px;
height: 24px;
flex-shrink: 0;
margin: 66px 724px 319px 32px;
}
.send_tittle{
margin: -416px 724px 200px 100px;
width: 100%;
position: absolute;
}
.send_tittle h3{
    margin: 66px 724px 319px 32px;
    position: relative;
}
.send_tittle > p{
    margin: -300px 724px 350px 5px;
    position: relative;
    color: rgb(51, 50, 50);
    font-weight: 600;
}
.send_body{
    position: absolute;
    width: 100%;

}
.send_body .method1{
    margin: -280px 724px 32px 55px;
    width: 280px;
    border: 1px solid rgb(205, 203, 203);
    background-color: aliceblue;
    cursor: pointer;
}
.send_body .method1:hover{
    background: rgb(231, 249, 213);
}
.send_body .method1 > h3{
    color: rgb(81, 81, 81);
    font-size: 18px;
    margin: 0px 724px 10px 20px;
    width: 100%;
}

.send_body .method1 > p{
    margin: 0px 0px 10px 20px;
    width: 90%;
    color: black;
    position: relative;
}


.send_body .method2{
    margin: 0px 724px 32px 55px;
    width: 280px;
    border: 1px solid rgb(205, 203, 203);
    background-color: aliceblue;
    cursor: pointer;
    text-decoration: dotted;
}
.send_body .method2:hover{
    background: rgb(231, 249, 213);
}
.send_body .method2 > h3{
    color: rgb(81, 81, 81);
    font-size: 18px;
    margin: 0px 724px 10px 20px;
    width: 100%;
    
}
.send_body .method2 > p{
    margin: 0px 0px 10px 20px;
    width: 90%;
    color: black;
    position: relative;
}


.send_body .method3{
    margin: 0px 724px 32px 55px;
    width: 280px;
    border: 1px solid rgb(205, 203, 203);
    background-color: aliceblue;
    cursor: pointer;
}
.send_body .method3:hover{
    background: rgb(231, 249, 213);
}
.send_body .method3 > h3{
    color: rgb(81, 81, 81);
    font-size: 18px;
    margin: 0px 724px 10px 20px;
    width: 100%;
}

.send_body .method3 > p{
    margin: 0px 0px 10px 20px;
    width: 90%;
    color: black;
    position: relative;
    text-align: left;
}

@media screen and (max-width: 615px){
    .container{
        width: 100vw;
        height: 100vh;
    }
    .send_tittle > h3{
        width: 100%;
    }
}

/* Add Account */
.add_acct_inset{
    width: 375px;
height: 280px;
flex-shrink: 0;
border-radius: 50px;
background: #121212;
margin: 430px 0 0 0;
position: absolute;
}
.add_acct_inset > h2{
    margin: 48px 247px 96px 97px;
    position: absolute;
}
.ac_btn{
    margin: 150px 113px 32px 32px;
}
.ac_btn .ac_btn_inset{
    cursor: pointer;
}
.ac_btn > p{
    position: absolute;
    margin: 80px 82px 81px 51px;
}
.header{
    margin: 26px 727px 10px 22px;
    position: absolute;
    width: 100%;
    display: flex;
    
}
.ac_form{
    width: 311px;
height: 182px;
border-radius: 50px;
background: #FFF;
position: absolute;
margin: 100px 522px 32px 32px;
display: grid;
}
.ac_form form{
    display: grid;
}
.ac_form form label{
    color: #121212;
    font-family: "TT Firs Neue Trl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}
.ac_form form option, input{
    color: #868686;
font-family: "TT Firs Neue Trl";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: left;
margin-bottom: 10px;

}
.ac_form select, option{
    width: 311px;
height: 50px;
flex-shrink: 0;
border-radius: 16px;
background: #EEEFF6;
outline: none;
border: none;
}
.ac_form form .add > input{
    width: 55px;
    height: 25px;
    margin: 0 0 0px 125.5px;
    border-radius: 0;
    background-color: #016f94;
    color: white;
    font-weight: 500;
    cursor: pointer;
}
.ah{
    display: flex;
    width: 311px;
    position: absolute;
    margin: 220px 522px 32px 12px;
    border-bottom: 1px solid rgb(243, 242, 242);
    padding-bottom: 20px;
    cursor: pointer;
}
.ah:hover{
    background:rgb(232, 244, 229);
}
.ah .ah_logo{
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 16px;
background: rgb(247, 247, 68);
text-align: center;
align-items: center;
align-self: center;
margin-top: 20px;
position: relative;
    
}
.ah .ah_logo:hover{
    background: #CDFF65;
}
.ah_body{  
    width: 311px;
    height: 50px;
    margin-left: 10px;
}
.ah_body h4{
    color: #121212;
    font-family: "TT Firs Neue Trl";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 20px;
}
.ah_body .ah_body_con h5, h6{
    color: #121212;
    font-family: "TT Firs Neue Trl";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: -10px;
}
.ah_body .ah_body_con h6{
    border-left: 1px solid rgb(243, 242, 242);
    height: 15px;
    margin-left: 5px;
    padding-left: 5px;
}
.ah_body .ah_body_con{
    display: flex;
    width: 311px;
    position: relative;
    height: 50px;

}

/* Payment Details */
.pd_inset{
    width: 375px;
height: 215px;
flex-shrink: 0;
border-radius: 50px;
background: #121212;
margin-top: 65px;
position: absolute;
}
.ac_btn_pd{
    margin: 32px 113px 32px 32px;
}


.pd_body{
    display: grid;
}
.pd_body h2{
    color: #121212;
font-family: "TT Firs Neue Trial Var Roman";
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: 117%; /* 42.12px */
margin: 152px 618px 96px 97px ;
}
.pd_body > p{
    color: #121212;
font-family: "TT Firs Neue Trl";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 202px 595px 148px 149px ;
}
.pd_body .pd_details{
    margin: 249px 381px 32px 32px;
    position: absolute;
}
.pd_body .pd_details > .to, .account_number, .bank_name{
    width: 311px;
height: 50px;
flex-shrink: 0;
border-radius: 16px;
background: #EEEFF6;
display: inline-flex;
margin-bottom: 16px;


}
.pd_body .pd_details  h4{
    color: #121212;
font-family: "TT Firs Neue Trl";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 17px 18px 279px 18px;

}
.pd_body .pd_details  p{
    color: #121212;
font-family: "TT Firs Neue Trl";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 17px 18px 18px 175px;
text-align: right;
width: 80%;
}
 /* Payment Confirmation */
 .pc_inset{
    width: 375px;
height: 400px;
flex-shrink: 0;
border-radius: 50px;
background: #121212;
margin-top: -180px;
display: absolute;
opacity: 1;
 }
 .pc_inset > p{
    color: #FFF;
text-align: center;
font-family: "TT Firs Neue Trial Var Roman";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 117%; /* 23.4px */
margin: 48px 388px 84px 88px;
 }
 .pc_inset .pc_input{
    display: flex;
    margin: 95px 299px 32px 32px;
    position: absolute;
    width: 311px;
    justify-content: space-between;
 }
 .pc_inset .pc_input input{
    width: 65px;
height: 65px;
flex-shrink: 0;
border-radius: 16px;
background: #FFF;
 }
 .pc{
    opacity: 0.2;
 }
 .pd_body .pc_details{
    margin: 200px 381px 32px 32px;
    position: absolute;
    
}
.pd_body .pc_details > .to{
    width: 311px;
height: 50px;
flex-shrink: 0;
border-radius: 16px;
background: #EEEFF6;
display: inline-flex;
margin-bottom: 16px;
}
.pc_details > .account_number, .bn{
    display: none;
}
.pd_body .pc_details  h4{
    color: #121212;
font-family: "TT Firs Neue Trl";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 17px 18px 279px 18px;
opacity: 1;

}
.pd_body .pc_details  p{
    color: #121212;
font-family: "TT Firs Neue Trl";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 17px 18px 18px 175px;
text-align: right;
width: 80%;
opacity: 1;
}
.pc_btn_pd{
    margin: 276px 113px 32px 32px;
    position: absolute;
}
.pc_cancle svg{
    width: 50px;
height: 50px;
flex-shrink: 0;
margin: -230px 482px 162px 163px;
position: absolute;
opacity: 0.5;
cursor: pointer;

}