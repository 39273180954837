

.frame .splash_logo img{
    width: 198.155px;
    height: 45px;
    margin: 300px 285px 88.8px 88px;
}
@font-face {
    font-family: TT Firs Neue Trl;
    src: url(../Font/tt_firs_neue/TT\ Firs\ Neue\ Trial\ Light.ttf);
}
 .copyright p{
    color: #FFF;
    font-family: TT Firs Neue Trl;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 200px 84px 88px 135px;
    height: 15px;
}
/* @media screen and (max-width: 412px) {
    .frame{
      border-radius: 1px;
      background-color: red;
    }
  } */
