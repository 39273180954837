.proof svg{
    width: 24px;
height: 24px;
flex-shrink: 0;
margin: 64px 724px 319px 32px;
}
.proof p{
    color: #121212;
text-align: center;
font-family: "TT Firs Neue Trial Var Roman";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 117%; /* 18.72px */
margin: -344px 727px 92px 91px;

}
.proof h2{
    color: #121212;
font-family: "TT Firs Neue Trial Var Roman";
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: 117%; /* 42.12px */
margin: -262px 618px 96px 97px;
}
.proof h3{
    color: #121212;
    font-family: "TT Firs Neue Trl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    margin: -230px 595px 139px 149px;
}
.proof .photo_container{
    width: 311px;
height: 116px;
flex-shrink: 0;
border-radius: 16px;
background: #EEEFF6;
margin: -120px 447px 32px 32px;
}
.proof .photo_container svg{
    width: 50px;
height: 50px;
flex-shrink: 0;
margin: 33px 33px 130px 131px;
}
.proof .file_container{
    width: 311px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 16px;
border: 1px solid #4F56D3;
margin: -10px 0px 32px 32px;
}
.proof .file_container input{
    color: #4F56D3;
text-align: center;
font-family: "TT Firs Neue Trl";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 150px;
height: 18px;
margin: 15px 17px 80px 81px;
border: none;
outline: none;
}

.proof_inset{
    width: 375px;
height: 215px;
flex-shrink: 0;
border-radius: 50px;
background: #121212;
margin-top: 40px;
position: absolute;
}
.proof_btn{
    margin: 32px 113px 32px 32px;
    width: 311px;
height: 70px;
flex-shrink: 0;
border-radius: 50px;
background: #8F8F8F;
cursor: pointer;

color: #FFF;
text-align: center;
font-family: "TT Firs Neue Trl";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.photo_container img{
    width: 311px;
height: 116px;
flex-shrink: 0;
border-radius: 16px;

}
.proof_btn_confirm{

    width: 311px;
height: 70px;
flex-shrink: 0;
border-radius: 50px;
background: #CDFF65;
margin: 32px 113px 32px 32px;
cursor: pointer;

color: #121212;
text-align: center;
font-family: "TT Firs Neue Trl";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}