


@font-face {
    font-family: TT Firs Neue Trial Var Roman;
    src: url(../Font/tt_firs_neue/TT\ Firs\ Neue\ Trial\ Var\ Roman.ttf);
}
h2{
    width: 250px;
    height: 184px;
    color: #FFFFFF;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: -360px 240px 48px 77px;
    
}
.frame > p{
    width: 204px;
    height: 15px;
    color: #FFFFFF;
    font-family: TT Firs Neue Trl;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: -30px 201px 48px 77px;
}
.arrow{
    width: 107px;
    height: 107px;
    flex-shrink: 0;
    position: absolute;
    margin: 60px 46px 32px 236px;
    text-align: center;
}
.inset{
    width: 44px;
    height: 40px;
    flex-shrink: 0;
    color: #121212;
    top: 680;
    text-align: center;
   
    
}

.arrow>img{
    margin: -8px 0  200px -25px;
    position: absolute;   
}