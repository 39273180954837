
.component_btn .component_btn_input{
    width: 311px;
height: 70px;
flex-shrink: 0;
border-radius: 50px;
background: #CDFF65;
    cursor: pointer;
    position: absolute;

    color: #121212;
text-align: center;
font-family: "TT Firs Neue Trl";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

