.header > p{
    position: relative;
    margin: 5px 0 0 35px;
    color: #121212;
font-family: "TT Firs Neue Trial Var Roman";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 117%; /* 18.72px */

}