@font-face {
    font-family: TT Firs Neue Trl;
    src: url(../Font/tt_firs_neue/TT\ Firs\ Neue\ Trial\ Light.ttf);
}
/* Component one */
.dashboard_frame{
    box-sizing: border-box;
    width: 375px;
    height: 96vh;
    margin: auto;
    border-radius: 50px;
    background: #FFF;
    overflow: hidden;
    position: fixed;
}
.component1{
    display: flex;
    width: 375px;
}
.component1 img{
    width: 41px;
    height: 41px;
    flex-shrink: 0;
    border-radius: 41px;
    margin: 64px 707px 302px 32px;
    /* background: url(../pages/assets/Ellipse8.jpg), lightgray 50% / cover no-repeat; */
}
.component1 > p{
    color: #121212;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 16.38px */
    width: 103px;
    height: 32px;
    margin: 68px 712px 183px 89px;
    text-align: left;
}
.component1 > .notification{
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #E8E9FE;
    position: absolute;
    margin: 64px 708px 32px 303px;
}
.notification > img{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin: 8px 716px 40px 8px;
}

/* Component Two */
.card{
    width: 311px;
    height: 171px;
    flex-shrink: 0;
    border-radius: 25px;
    background: #121212;
    display: grid;
    margin: -270px 137.93px 32px 32px;
    box-sizing: border-box;
}
.card .card_strip{
    position: absolute;
    width: 846.5px;
    height: 790.5px;
    flex-shrink: 0;
    stroke-width: 8px;
    stroke: #CDFF65;
    /* opacity: 0.4; */
}

.card > p{
    color: #EEEFF6;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 16.38px */
    margin: 34px 121px 177px 32px;
 
     
}

.card .down_arrow svg{
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin: 32px 119px 85px 206px;
   
}
/* .card_comp1 .usd{
    align-items: center;
    justify-content: center;
} */
.card .usd > p{
    margin: -105px 35px 56px 230px;
    color: #F0F0F0;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 14.04px */
    
}
.card > img{
    /* width: 16px;
    height: 16px; */
    flex-shrink: 0;
    margin: 28px 121px 32px 263px;
    position: absolute;
    
}
.card > h2{
    color: #FFF;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 42.12px */
    margin: -95px 62px 126px 32px;
}

/* Component 3 */
.db_button{
    display: grid;
    width: 100%;
}
.db_button .db_btn1 > .send_money{
    width: 148px;
    height: 55px;
    flex-shrink: 0;
    border-radius: 25px;
    background: #E8E9FE;
    margin: -324px 433px 195px 32px;
   

    color: #121212;
    text-align: center;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 16.38px */
    cursor: pointer;
}
.db_button .db_btn1 > input{
    position: relative;
}
.db_button .db_btn2 > input{
    width: 148px;
    height: 55px;
    flex-shrink: 0;
    border-radius: 25px;
    background: #E8E9FE;
    margin: -250px 433px 32px 195px;
    position: absolute;
    cursor: pointer;

    color: #121212;
    text-align: center;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 16.38px */
}

/* Component 3 */
.component3{
    display: grid;
    position: absolute;
    /* overflow-y: scroll; */
   
}
.component3 > p{
    color: #121212;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 18.72px */
    margin: -160px 382px  233px 32px;
    position: absolute;
    width: 110px;
    height: 19px;

}
.component3 > svg{
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin: -160px 381px 199px 156px;
    position: absolute;

}
.component3 .component3_scroll{
    width: 311px;
    height: 201px;
    flex-shrink: 0;
    box-sizing: border-box;
    margin: -120px 324px 32px 32px;
    position: absolute;

}
.component3 .component3_scroll::-webkit-scrollbar{
    display: none;
}
.component3_scroll .each_transact{
    position: relative;
}
.component3 .component3_scroll .each_transact2{
    margin: -35px 1px 3px 3px;
}
.component3 .component3_scroll .each_transact3{
    margin: -35px 1px 3px 3px;
}

.component3 .each_transact img{
    width: 40.995px;
    height: 41px;
    flex-shrink: 0;
}
.transact_inset{
    width: 256px;
    height: 30px;
    flex-shrink: 0;
    /* background-color: red; */
    box-sizing: border-box;
    /* position: absolute; */
    margin: -35px 5px 55px 55px;
}
.transact_inset .asset_name{
    display: block;
}
.transact_inset .asset_name>p{
    position: relative;
    margin: 0 16px 215px 0px;
    color: #121212;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 117%; /* 14.04px */
    
}
.transact_inset .asset_name > h5{
    color: #8F8F8F;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%; /* 11.7px */
    margin: -216px 0px 215px 0px;
}
.transact_inset .asset_price>p{
    color: #000000;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 117%; /* 14.04px */
    position: relative;
    margin: -240px 16px 0px 215px;
}
.transact_inset .asset_price>h5{
    color: #8F8F8F;
    font-family: TT Firs Neue Trial Var Roman;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    /* position: relative; */
    line-height: 117%; /* 11.7px */
    margin: 0px 1px 0px 189px;
}
.transact_inset .asset_price{
    display: block;
}
.component3_scroll{
    overflow-y: scroll;
}
.component3_scroll .each_transact2{
    margin: -60px 267px 302px 32px;
}
.component3 .each_transact3{
    margin: -50px 267px 302px 32px;
}

.scroll{
    overflow-y: scroll;
    overflow-x: hidden;
}
.scroll::-webkit-scrollbar{
    display: none;
}
.info{
    background-color: red;
}
.bottom_menu{
    margin-top: -45px;
    position: absolute;
}

@media screen and (max-width: 412px) {
    .dashboard_frame{
      border-radius: 0;
      width: 100%;
      height: 100%;
    }
  }