.wallet_container{
    cursor: pointer;

}
.wallet_container >svg{
    width: 85px;
    height: 85px;
    flex-shrink: 0;
    fill: #CDFF65;
    border: 6px solid white;
    border-radius: 50px;
    margin: 10px 48px 139px 139px;
    position: relative;
}
.wallet svg{
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    color: #121212;
    margin: -210px 89px 172px 170px;
    position: absolute;
}
.wallet_container >p{
    color: #121212;
    text-align: center;
    font-family: TT Firs Neue Trl;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* position: absolute; */
    margin: -180px 72px 172px 170px;
}
.menu_box{
    width: 327px;
    height: 77px;
    border-radius: 25px;
    background: #121212;
    margin: -200px 24px 24px 24px;
    /* position: absolute; */
}
.home{
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin: 14px 43px 261px 46px;
    position: absolute;
}
.home > p{
    color: #CDFF65;
    text-align: center;
    font-family: TT Firs Neue Trl;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px 26px 281px -4px;
    position: absolute;
}
.settings{
    cursor: pointer;
}
.settings svg{
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin: 14px 43px 32px 255px;
    cursor: pointer;
}
.settings > p{
    color: #6C6C6C;
    text-align: center;
    font-family: TT Firs Neue Trl;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: -33px 26px 33px 245px;
   
}